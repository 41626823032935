import React, { useEffect, useState } from "react";
import { useLocation } from "@reach/router";
import qs from "qs";
import Layout from "../components/common/layout/layout";
import { Navigation } from "../components/common/navigation/navigation";
import { Footer } from "../components/sections/footer";
import {
  OpenCorrectZorbiLink,
  useCanUseExtension,
  useDetectBrowser,
} from "../utils";
import { Formik, Field, Form } from "formik";
import { TrackingAPI } from "../utils/trackingapi";

import SEO from "../components/common/layout/seo";

const UninstallPage = () => {
  const location = useLocation();
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const canUseExtension = useCanUseExtension();
  const browser = useDetectBrowser();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [userId] = useState(queryParams.userId);

  useEffect(() => {
    if (userId) {
      TrackingAPI.trackUninstall(userId);
    }
  }, []);

  return (
    <Layout>
      <SEO title="Sorry to see you go" />
      <Navigation />
      <section className="py-20">
        <div className="container px-4 mx-auto">
          <div className="max-w-2xl mx-auto text-center">
            <div className="max-w-md mx-auto mb-8">
              <h2 className="mb-2 text-4xl font-bold font-heading">
                Sorry to see you go
              </h2>
              <span className="text-sm text-blueGray-400">
                Please let us know why you uninstalled Zorbi
              </span>
            </div>
            <div className="relative">
              <Formik
                initialValues={{
                  reasons: [],
                  message: "",
                }}
                onSubmit={async (values) => {
                  if (userId) {
                    TrackingAPI.trackUninstall(
                      userId,
                      values.reasons,
                      values.message
                    );
                  }
                  setFormSubmitted(true);
                }}
              >
                {({ values, errors }) => (
                  <Form>
                    {reasons.map((reason, index) => (
                      <ReasonCheckbox reason={reason} key={index} />
                    ))}
                    <div className="mb-4">
                      <Field
                        className="w-full h-24 p-4 text-xs font-semibold leading-none rounded outline-none resize-none bg-blueGray-50"
                        type="text"
                        placeholder="Tell us more... (optional)"
                        name="message"
                        value={values.message}
                        component="textarea"
                      ></Field>
                    </div>
                    <div className="flex items-start justify-between">
                      <div className="w-3/5">
                        <p className="mb-2 text-sm leading-relaxed text-left text-blueGray-400">
                          To contact the team, email{" "}
                          <a
                            className="underline"
                            href="mailto:contact@zorbiapp.com"
                          >
                            contact@zorbiapp.com
                          </a>
                          .
                        </p>
                      </div>
                      <button
                        className="px-8 py-4 text-sm font-semibold leading-none text-white bg-purple-600 rounded hover:bg-purple-700 disabled:opacity-50"
                        type="submit"
                        disabled={
                          formSubmitted ||
                          (values.reasons.length === 0 &&
                            values.message.length === 0)
                        }
                      >
                        Submit
                      </button>
                    </div>
                    {formSubmitted && (
                      <p className="absolute w-full mt-4 font-semibold leading-none text-center -bottom-8 text-blueGray-800">
                        Thanks! Your feedback has been submitted.
                      </p>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-20 pb-24 bg-purple-600">
        <div className="max-w-xl mx-auto text-center">
          <span className="inline-block px-3 py-1 text-xs font-semibold text-white bg-purple-500 rounded-xl">
            Made a mistake?
          </span>
          <h2 className="mt-3 text-3xl font-bold text-white md:text-4xl font-heading">
            No worries. Come back to Zorbi again for free.
          </h2>
          <div className="mt-6">
            <a
              className="inline-block px-8 py-4 text-xs font-semibold leading-none text-blue-600 transition duration-300 bg-white border rounded hover:bg-blue-600 hover:text-white hover:border-white"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                OpenCorrectZorbiLink();
              }}
            >
              {canUseExtension
                ? browser && browser.name.includes("edge")
                  ? "Add to Edge"
                  : "Add to Chrome"
                : "Try Zorbi"}
            </a>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  );
};

export default UninstallPage;

const reasons = [
  "It's not what I expected",
  "It's too difficult to use",
  "I faced technical problems or glitches",
  "It doesn't have the features I want",
  "It doesn't integrate with the tools or services I use",
  "I prefer just using the app",
  "I don't need it any more",
  "Other",
];

const ReasonCheckbox = (props) => {
  return (
    <div className="pl-2 mb-4 text-left">
      <label className="block font-semibold leading-none text-blueGray-800">
        <Field
          name="reasons"
          className="mr-2 leading-tight"
          type="checkbox"
          value={props.reason}
        />
        <span className="text-md">{props.reason}</span>
      </label>
    </div>
  );
};
